.MuiFormHelperText-root {
  color: white !important;
  font-size: 11px !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #ff6c6c;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none !important;
  outline: none;
  color: rgba(75, 34, 11, 0.8);
}

.btn-style-one {
  position: relative;
  background-color: transparent;
  color: #fff !important;
  display: inline-block;
  padding: 10px 25px;
  font-size: 25px;
  font-weight: 600;
  border-radius: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 247px;
  height: 62px;
  text-align: center;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #c7144f;
  border-radius: 50px;
  border: 2px solid #fff;
  transition: all 0.3s ease-in-out;
}

.btn-style-one:hover {
  background-color: #f3d33d;
  color: #000 !important;
}

.btn-style-two {
  position: relative;
  background-color: #b6673c;
  color: #fff !important;
  display: inline-block;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 35px;
  transition: all 0.3s ease-in-out;
}

.btn-style-two:hover {
  background-color: #df956d;
}

.flappynomic-sec {
  position: relative;
  overflow: hidden;
  padding: 100px 0;
}


.flappynomic-sec .tokenomics-box-center {
  position: relative;
  width: 100%;
  margin: 255px auto;
  z-index: 3;
}


.flappynomic-sec .tokenomics-box-center .tokenomics-center-img {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-center-img img {
  width: 60%;
  filter: drop-shadow(0px 0px 250px #ffffff);
}

.flappynomic-sec .tokenomics-box-center .tokenomics-center-img .overlyabox {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -16%);
  width: 158px;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-center-img .overlyabox img {
  width: 100%;
  filter: none;
}


.flappynomic-sec .tokenomics-box-center .tokenomics-center-img .arrows-img {
  position: absolute;
  top: -63px;
  left: 50%;
  transform: translateX(-46%);
  width: 100%;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-center-img .arrows-img img {
  width: 100%;
  filter: none;
}


.flappynomic-sec .tokenomics-box-center .tokenomics-box {
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-box:hover {
  transition: all 0.3s ease-in-out;
  animation: wiggle 8s linear infinite;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-box .img-box {
  position: relative;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-box .img-box img {
  width: 70%;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-1 {
  top: -191px;
  left: 127px;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-2 {
  top: -175px;
  right: -133px;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-3 {
  bottom: -126px;
  left: 171px;
}

.flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-4 {
  bottom: -35px;
  right: -147px;
}

.flappytrump-page .modal {
  /* top: 20%; */
  /* left: 35%; */
  /* width: 500px; */
  /* height: 500px; */
  /* background: #6ed806; */
}

section.banner-area {
  position: relative;
}

.background-start {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  /* background-image: url('../../static/images/main-banner.jpg');
  background-size: 100% 100%; */
}

.banner-area.banner-sec .background-start {
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('../../static/images/main-banner.png');
  background-size: 100% 100%;
  z-index: 1;
}

.banner-area.banner-sec .background-start::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 119px;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 1;
  background-image: url('../../static/images/bottom-banner-area.png');
}

.background-start .text-box {
  position: relative;
  text-align: center;
  width: 609px;
  margin: 5px auto 0;
  top: 225px;
}

.background-start .text-box img {
  width: 450px;
}

.cherctor-images {
  position: absolute;
  text-align: right;
  width: 100%;
  bottom: 117px;
  left: -99px;
}

.cherctor-images img {
  width: 177px;
  position: relative;
  bottom: -91px;
  margin-right: 32px;
}

.cherctor-images img.charctor-img {
  position: relative;
  z-index: 2;
  width: 298px;
  bottom: auto;
}

.background-start .left-play-area {
  position: absolute;
  left: 24px;
  bottom: 81px;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 29px 126px 115px 157px;
  width: 641px;
  z-index: 99;
  background-image: url('../../static/images/left-play-btn.png');
  background-size: 100%;
  animation: shadowAnimation 5s infinite;
}

.social-icons ._wrapper_1sefb_1 ._wrapper_exo6z_1 ._loop_1v4pf_1,
.social-icons ._wrapper_1sefb_1 ._wrapper_exo6z_1 ._shuffle_15ul3_1 {
  display: none;
}

.background-start .left-play-area .tap-area {
  cursor: pointer;
  padding-top: 11px;
}

._wrapper_1ssds_1 {
  grid-template-columns: auto 49% !important;
}

.rhap_additional-controls {
  display: none !important;
}

._container_1kekm_1 {
  display: none !important;
}

@keyframes shadowAnimation {
  50% {
    filter: drop-shadow(-10px -10px 0px #b4511f);
  }
}

.background-start .left-play-area h3 {
  display: flex;
  align-items: center;
  color: #942a13;
  justify-content: center;
  white-space: nowrap;
  margin: 0 auto 20px;
  font-size: 39px;
}

._play_rfl62_1,
._next_147p5_1,
._previous_1mino_1,
._pause_1talx_1 {
  width: 25px !important;
  height: 25px !important;
}

._wrapper_1ssds_1 {
  margin-bottom: 0px !important;
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: orange;
  }
}

.social-icons ._wrapper_1sefb_1 {
  background-color: transparent;
  background-size: 100% 100%;
  padding: 25px 41px;
  zoom: 0.4;
  width: 350px;
  background-image: url('../../static/images/audio-bg.png');
}

.react-h5-audio-player .toggle-play-wrapper .toggle-play-button {
  background-color: red !important;
}

.react-h5-audio-player .flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.react-h5-audio-player .toggle-play-wrapper {
  flex: 1 0 60px;
  -webkit-box-flex: 1 0 60px;
  -moz-box-flex: 1 0 60px;
  -ms-flex: 1 0 60px;
}

.react-h5-audio-player .progress-bar-wrapper {
  flex: 10 0 auto;
  -webkit-box-flex: 10 0 auto;
  -moz-box-flex: 10 0 auto;
  -ms-flex: 10 0 auto;
}

.social-icons .rhap_container {
  width: 344px !important;
  padding: 19px 35px !important;
  background-color: transparent !important;
  background-size: 100% 100%;
  background-image: url('../../static/images/audio-bg.png') !important;
  zoom: 0.4;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button,
button.rhap_button-clear.rhap_main-controls-button.rhap_skip-button[aria-label="Previous"] {
  display: none;
}

.rhap_main {
  flex-direction: row !important;
  flex: none !important;
  justify-content: space-between;
}

.rhap_progress-section {
  flex: none !important;
  position: relative;
  order: 2;
}

.rhap_progress-section:before {
  content: "/";
  position: absolute;
  left: 50%;
  top: 25px;
  font-size: 25px;
  color: #fff;
  transform: translateX(-48%);
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #670913 !important;
  border-radius: 2px;
}

.rhap_progress-bar-show-download,
.rhap_volume-bar {
  background-color: #670913 !important;
}

.rhap_repeat-button,
.rhap_main-controls-button,
.rhap_volume-button,
.rhap_time {
  color: #fff !important;
  background-color: transparent;
  font-size: 19px !important;
  margin-top: 7px;
}

.rhap_main-controls {
  order: 2;
}

.rhap_repeat-button svg,
.rhap_main-controls-button svg,
.rhap_volume-button svg,
.rhap_time svg {}

.rhap_main-controls-button,
.rhap_volume-button {
  width: 65px !important;
  height: 65px !important;
}

.rhap_volume-button {
  display: flex;
  align-content: center;
  flex: none !important;
}

.rhap_container svg {
  font-size: 63px;
}

.rhap_volume-indicator {
  width: 32px;
  height: 32px;
  position: relative;
  top: -4px;
}

.rhap_volume-bar {
  width: 100px;
  height: 7px;
}

.rhap_progress-bar-show-download {
  display: none;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
}

.rhap_progress-indicator,
.rhap_volume-indicator {
  background-image: url('../../static/images/input-range-round.png') !important;
  background-repeat: no-repeat;
}

._slider_1er3w_13 {
  width: 100% !important;
}

._slider_1er3w_13::-webkit-slider-thumb,
._slider_1kekm_13::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background-image: url('../../static/images/input-range-round.png') !important;
  cursor: pointer;
}

.rhap_progress-filled {
  background-color: #88c852 !important;
}

.rhap_download-progress {
  background-color: #670913 !important;
}

._slider_1er3w_13::-moz-range-thumb,
._slider_1kekm_13::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background-image: url('../../static/images/input-range-round.png') !important;
  cursor: pointer;
}

.social-icons ._wrapper_1sefb_1 ._wrapper_l8wb1_1 {
  margin: 10px 0 0;
  grid-template-columns: auto 100px
}

._title_1ldbx_1,
._time_ac0au_1 {
  margin-bottom: 0px !important;
}

._wrapper_1er3w_1 {
  min-height: 30px !important;
}

section.banner-area.banner-game .bottom-image {
  position: absolute;
  bottom: -28px;
  z-index: 2;
}

section.banner-area.banner-game .bottom-image img {
  width: 100%;
}

section.banner-area.banner-game .supp-image {
  position: absolute;
  bottom: -28px;
  animation: imgRotator 9s ease-in-out infinite;
}

.banner-area.banner-game .sc-gEvEer:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 132px;
  width: 100%;
  height: 100%;
  animation: imgRotator 9s ease-in-out infinite;
  background-image: url('../../static/images/background-supp.png') !important;
}

.banner-area.banner-game .sc-gEvEer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 157px;
  background-image: url('../../static/images/bottom-bg.png') !important;
}

@keyframes imgRotator {
  0% {
    left: 100px;
  }

  17%,
  33% {
    left: 0;
  }

  50%,
  100% {
    /* 33 + 17 = 50 */
    left: -100px;
  }
}

.banner-area.banner-game .social-icons {
  position: absolute;
  bottom: 24px;
}

.background-start,
.leaderboard-screen {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.social-icons ._wrapper_1sefb_1 ._container_1kekm_1 input {
  background: linear-gradient(90deg, var(#6ed806) 0%, var(#6ed806) 50%, var(#6ed806) 50%, var(#6ed806) 75%, var(#6ed806) 75%, var(#6ed806) 100%) !important;
}

.social-icons ._wrapper_1sefb_1 ._wrapper_l8wb1_1 h1._title_1ldbx_1 {
  font-size: 22px;
  text-shadow: 0 0 BLACK;
}

.social-icons ._wrapper_1sefb_1 ._wrapper_l8wb1_1 h1._time_ac0au_1 {
  font-size: 22px;
}

.background-start .left-play-area h3 .icon {
  margin-right: 17px;
  width: 45px;
  animation: zoom-in-zoom-out-2 3s ease infinite;
}

.background-start .left-play-area p {
  text-align: center;
  line-height: 1.2;
  color: #6d1801;
  font-family: 'Berlin Sans FB Demi';
  font-size: 20px;
}

.flappynomic-sec {
  position: relative;
  overflow: hidden;
}

section.banner-area {
  position: relative;
}

.flappynomic-sec .cloud-1 {
  top: auto;
  bottom: 0;
  width: 100%;
}

.flappynomic-sec .cloud-1 img {
  width: 100%;
}

.flappynomic-sec .kite-1 {
  position: absolute;
  bottom: 111px;
  right: 37px;
}

.flappynomic-sec .kite-2 {
  position: absolute;
  left: 350px;
  top: -60px;
}

.flappynomic-sec .plance-2 {
  position: absolute;
  top: 30%;
  left: -60px;
}

.flappynomic-sec .plance-3 {
  position: absolute;
  right: -19px;
  top: -78px;
}

.flappynomic-sec .cloude-token {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  z-index: 3;
}

.flappynomic-sec .cloude-token .cloude-token-box {
  width: 25.33%;
  margin: 0 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.flappynomic-sec .cloude-token .cloude-token-box:hover {
  animation: bounce2 2s ease infinite;
}



.flappymap-sec {
  position: relative;
  padding: 100px 0 0;
}

.flappymap-sec .container-fluid .col-12 {
  padding: 0;
}

.flappymap-sec .roadmaap-area {
  position: relative;
}

.flappymap-sec .roadmaap-area .roadmap-bg {
  position: relative;
}

.flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block {
  position: absolute;
}

.flappymap-sec .roadmaap-area .roadmap-bg img {
  margin-top: 100px;
}

.flappymap-sec .roadmaap-area .roadmap-bg .rocket img {
  -webkit-animation: animPipe 14s infinite;
  animation: animPipe 14s infinite;
  position: relative;
}

.flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block {
  position: absolute;
  zoom: 0.8;
}

.flappymap-sec .roadmaap-area .roadmap-bg img {
  margin-top: 100px;
}

.flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-1 {
  left: 143px;
  top: 60px;
  -webkit-animation: mover 4s infinite alternate;
  animation: mover 4s infinite alternate;
}

.flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-2 {
  left: 44%;
  top: -77px;
  -webkit-animation: mover 6s infinite alternate;
  animation: mover 6s infinite alternate;
}

.flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-3 {
  right: 124px;
  top: -169px;
  -webkit-animation: mover 8s infinite alternate;
  animation: mover 8s infinite alternate;
}

.flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-4 {
  right: 117px;
  top: 332px;
  -webkit-animation: mover 10s infinite alternate;
  animation: mover 10s infinite alternate;
}

.flappymap-sec .cloud-2 {
  top: 140px;
}

.modal-enter-website .body-modal .modal-inner .loader img {
  width: 106px;
}

@keyframes bounce2 {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes animGround {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: -335px 0px;
  }
}

@-moz-keyframes animGround {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: -335px 0px;
  }
}

@-o-keyframes animGround {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: -335px 0px;
  }
}

@keyframes animGround {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: -335px 0px;
  }
}

@-webkit-keyframes animSky {
  0% {
    background-position: 0px 100%;
  }

  100% {
    background-position: -671px 100%;
  }
}

@-moz-keyframes animSky {
  0% {
    background-position: 0px 100%;
  }

  100% {
    background-position: -671px 100%;
  }
}

@-o-keyframes animSky {
  0% {
    background-position: 0px 100%;
  }

  100% {
    background-position: -671px 100%;
  }
}

@keyframes animSky {
  0% {
    background-position: 0px 100%;
  }

  100% {
    background-position: -671px 100%;
  }
}

@-webkit-keyframes animTrump {
  from {
    background-position: 0px 0px;
  }

  to {
    background-position: 0px -96px;
  }
}

@-moz-keyframes animTrump {
  from {
    background-position: 0px 0px;
  }

  to {
    background-position: 0px -96px;
  }
}

@-o-keyframes animTrump {
  from {
    background-position: 0px 0px;
  }

  to {
    background-position: 0px -96px;
  }
}

@keyframes animTrump {
  from {
    background-position: 0px 0px;
  }

  to {
    background-position: 0px -96px;
  }
}

@-webkit-keyframes animPipe {
  0% {
    left: -100px;
  }

  100% {
    left: 550px;
    top: -80px;
  }

  100% {
    left: 900px;
    top: -160px;
  }
}

@-moz-keyframes animPipe {
  0% {
    left: -100px;
  }

  100% {
    left: 550px;
    top: -80px;
  }

  100% {
    left: 900px;
    top: -160px;
  }
}

@-o-keyframes animPipe {
  0% {
    left: -100px;
  }

  100% {
    left: 550px;
    top: -80px;
  }

  100% {
    left: 900px;
    top: -160px;
  }
}

@keyframes animPipe {
  0% {
    left: -100px;
  }

  100% {
    left: 550px;
    top: -80px;
  }

  100% {
    left: 900px;
    top: -160px;
  }
}

.rhap_volume-bar-area {
  display: none !important;
}

.rhap_controls-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.rhap_volume-container {
  flex: none !important;
}

.social-icons .rhap_container .rhap_header {
  position: relative;
  color: #fff;
  width: 100%;
  font-size: 26px;
  margin-top: 12px;
  margin-bottom: 0 !important;
}

.rhap_controls-section {
  flex: none !important
}

.flappytrump-page .sec-title {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.flappytrump-page .sec-title h2 {
  color: #6ed806;
  font-size: 70px;
  line-height: 144px;
  text-transform: uppercase;
  font-family: 'CCThatsAllFolks';
  -webkit-text-stroke: 6px #285100;
  text-stroke: 6px #285100;
}

.features-sec {
  position: relative;
  padding: 100px 0 0;
  overflow: hidden;
}

.flappytrump-page .sec-title h3 {
  font-size: 36px;
  color: #4B220B;
  font-weight: 600;
}

.cloud-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.cloud-1 img {
  width: 100%;
}

.cloud-2 {
  position: absolute;
  top: 40%;
  -webkit-animation: animateCloud 35s linear infinite;
  -moz-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;

  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
}

.cloud-3 {
  position: absolute;
  bottom: 20%;
  -webkit-animation: animateCloud 20s linear infinite;
  -moz-animation: animateCloud 20s linear infinite;
  animation: animateCloud 20s linear infinite;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.features-area {
  position: relative;
  display: flex;

}

.flappytrump-page .sec-title h2 {
  z-index: 6;
  position: relative;
}

.features-area .features-box {
  background-image: url('../../static/images/feature-1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 291px 34px 58px;
  width: 26.33%;
  height: 652px;
  margin-top: -81px;
  margin-left: -31px;
  text-align: center;

  transition: all 0.3s ease-in-out;
}

.features-area .features-box:hover {
  transition: all 0.3s ease-in-out;
  animation: wiggle 11s linear infinite;
}

.features-area .features-box h3 {
  font-size: 24px;
  color: #fff;
  font-family: 'Berlin Sans FB Demi';
  margin-bottom: 30px;
}

.midale-area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.features-area .features-box p {
  font-family: 'Berlin Sans FB Demi';
  line-height: 1.3;
  font-size: 21px;
}

.features-box-2 {
  background-image: url('../../static/images/feature-2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 113px 112px 58px;
  width: 39.33%;
  height: 652px;
  margin-top: -102px;
  margin-left: 194px;
  text-align: center;
  transition: all 0.3s ease-in-out;

}

.features-area .features-box-2:hover {
  transition: all 0.3s ease-in-out;
  animation: wiggle 8s linear infinite;
}

.leaderboard-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  zoom: 0.8;
  z-index: 99;
}

.leaderboard-screen .cross-icon {
  position: absolute;
  top: 19px;
  right: 0;
  background-color: transparent;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 45px;
  font-style: normal;
  cursor: pointer;
  z-index: 999;
}

.leaderboard-screen .user-score-area {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 40px 0;
  max-height: 286px;
  overflow-y: auto;
  margin-top: 97px;
}

.leaderboard-screen .user-score-area .score-top {
  width: 100%;
  text-align: right;
}

.leaderboard-screen .user-score-area .user-score-box {
  width: 100%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  zoom: 0.7;
  margin-bottom: 10px;
}

.leaderboard-screen .user-score-area .user-score-box .madile-img {
  width: 41px;
  margin-right: 35px;
}

.leaderboard-screen .user-score-area .user-score-box .user-area {
  width: 26%;
}

.leaderboard-screen .user-score-area .user-score-box .user-area img {
  width: 52px;
  margin: 0 15px;
}

.leaderboard-screen .user-score-area .user-score-box .score-area {
  width: 50%;
  text-align: right;
  position: relative;
}

.leaderboard-screen .user-score-area .user-score-box .score-area p {
  position: absolute;
  right: 28%;
  top: 1px;
  transform: translate(50%, 50%);
  color: #fff;
  font-size: 24px;
  font-family: 'Berlin Sans FB Demi';
}

.leaderboard-screen .user-score-area .user-score-box .user-area {
  width: 26%;
  display: flex;
  align-items: center;
}

.leaderboard-screen .user-score-area .user-score-box .user-area p {
  font-size: 16px;
  font-family: 'Berlin Sans FB Demi';
}

.features-box-2 .user-score-area {
  position: relative;
  zoom: 0.65;
  max-height: 390px;
  overflow-y: auto;
  padding-right: 9px;
}

.features-box-2 .user-score-area .score-top {
  display: flex;
  justify-content: end;
  padding-right: 41px;
}

.features-box-2 .user-score-area .user-score-box {
  position: relative;
  display: flex;
  margin-bottom: 8px;
  justify-content: space-around;
}

.features-box-2 .user-score-area .user-score-box .madile-img {
  width: 30px;
}

.features-box-2 .user-score-area .user-score-box .user-area {
  position: relative;
  display: flex;
  align-items: center;
}

.features-box-2 .user-score-area .user-score-box .score-area {
  width: 115px;
  position: relative;
}

.midale-area img {
  width: 50px;
}

.features-box-2 .user-score-area .user-score-box .user-area img {
  width: 39px;
}

.features-box-2 .user-score-area .user-score-box .user-area p.contery-name {
  width: 90px;
  font-size: 15px;
}

.features-box-2 .user-score-area .user-score-box .user-area p {
  position: relative;
  text-align: left;
  left: 0;
  font-size: 19px;
  margin: 0 5px;
}

.features-box-2 .user-score-area .user-score-box .user-area p span {
  margin-left: 24px;
}

.features-box-2 .user-score-area .user-score-box .score-area p {
  position: absolute;
  top: 7px;
  width: 100%;
  color: #fff;
}

.features-area .features-box-2 h3 {
  font-size: 27px;
  color: #fff;
  font-family: 'Berlin Sans FB Demi';
  margin-bottom: 67px;
}

.features-box-2 .more-btn {
  position: relative;
  margin-top: 7px;
  background-color: transparent;
  border: none;
}

.features-area .features-box-2 p {
  font-family: 'Berlin Sans FB Demi';
  line-height: 1.3;
  font-size: 21px;
}

.features-box-2 .table-leaderboard {
  position: relative;
}

.features-box-2 .table-leaderboard thead tr th {
  font-size: 15px;
  color: #6e1907;
  font-family: 'Berlin Sans FB Demi';
  width: 75px;
  text-align: left;
}

.features-box-2 .table-leaderboard thead tr th.last-area {
  width: 238px;
  text-align: end;
}

.features-box-2 .table-leaderboard tbody tr td {
  text-align: left;
  font-size: 13px;
  color: #6e1907;
  font-family: 'Berlin Sans FB Demi';
  background-color: #ccc;
  padding: 4px 10px;
  border-bottom: 5px solid #fff;
}

.features-box-2 .table-leaderboard tbody tr td.last-area {
  text-align: end;
}

.features-area .features-box.features-box-3 {
  margin-left: 116px;
  margin-top: -103px;
}

.features-sec .bttom-text p {
  font-size: 27px;
  color: #fff;
  font-family: 'Berlin Sans FB Demi';
  margin-bottom: 30px;
  text-shadow: 2px 2px #00000024;
}

.features-sec .bttom-text {
  position: relative;
  margin-top: 28px;
  text-align: center;
}

.marque-area {
  background-color: #6ed806;
  padding: 12px 0 16px;
  border-top: 2px solid #376e01;
  border-bottom: 2px solid #376e01;
}

.marque-area .marque-inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* animation properties */
  -moz-transform: translateX(-1000px);
  -webkit-transform: translateX(-1000px);
  transform: translateX(-1000px);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

.marque-area marquee.marq .geek1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.marque-area p {
  color: #e70d0d;
  font-family: 'Berlin Sans FB Demi';
  line-height: 1.3;
  font-size: 21px;
  margin-bottom: 0;
  text-transform: uppercase;

}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(-1000px);
  }

  to {
    -moz-transform: translateX(1000px);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-1000px);
  }

  to {
    -webkit-transform: translateX(1000px);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-1000px);
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }

  to {
    -moz-transform: translateX(1000px);
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
  }
}

.rocket {
  position: absolute;
  left: -54px;
  top: 179px;
  z-index: 9;
}

.join-sec .sec-title {
  margin-bottom: 150px;
}

.footer-img {
  position: relative;
  margin-top: -312px;
}

.footer-img img {
  width: 100%;
}

.rocket img {
  margin-top: 0;
  width: 200px;
}

.join-sec .col-lg-6 {
  padding: 0;
}

.join-sec .sec-title ul {
  display: flex;
  align-items: center;
  margin-top: 90px;
  justify-content: center;
  position: relative;
  z-index: 9;
}

.background-start .left-play-area .counter {
  display: block;
  position: relative;
  background-color: transparent;
  border: none;
  width: 216px;
  height: 43px;
  text-align: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Berlin Sans FB Demi' !important;
  transition: all 0.3s ease-in-out;
  background-size: 100% 100%;
  margin: -47px auto 0;
  font-size: 27px;
  cursor: pointer;
  background-image: url('../../static/images/menu-btn-bg.png');
}

.background-start .left-play-area .counter .icon {
  position: relative;
  width: 20px;
  display: inline-block;
  margin-right: 8px;
}

.background-start .text-box h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6ed806;
  -webkit-text-stroke: 3px #285100;
  text-stroke: 3px #285100;
  font-family: 'Berlin Sans FB Demi';
  font-size: 40px;
  cursor: pointer;
}

.background-start .text-box h3 .icon {
  width: 72px;
  margin-right: 0;
  animation: zoom-in-zoom-out-3 4s ease infinite;
  margin-top: 23px;
}

.join-sec .sec-title ul li {
  margin: 0 25px;
}

.join-sec .sec-title ul li a {
  transition: all 0.3s ease-in-out;
  animation: bounce2 5s ease infinite;
}

.join-sec .sec-title ul li a img {
  transition: all 0.3s ease-in-out;
  animation: bounce2 5s ease infinite;
}

.join-sec .sec-title ul li:nth-child(2) a img {
  transition: all 0.3s ease-in-out;
  animation: bounce2 6s ease infinite;
}

.join-sec .sec-title ul li a:hover {
  filter: drop-shadow(5px 5px #066d00);
}

.join-sec .sec-title ul li a img {
  width: 113px;
}

/* width */
.features-box-2 .user-score-area::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.features-box-2 .user-score-area::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.features-box-2 .user-score-area::-webkit-scrollbar-thumb {
  background: #ccc;
  border: 6px;
}

/* Handle on hover */
.features-box-2 .user-score-area::-webkit-scrollbar-thumb:hover {
  background: #ccc;
  border: 6px;
}

.flappynomic-sec {
  position: relative;
  overflow: hidden;
}

section.banner-area {
  position: relative;
}

.flappynomic-sec .cloud-1 {
  top: auto;
  bottom: 0;
  width: 100%;
}

.flappynomic-sec .cloud-1 img {
  width: 100%;
}

.flappynomic-sec .plance-1 {
  position: absolute;
  right: -349px;
  width: 38%;
  bottom: 16px;
  /* animation properties */
  -moz-transform: translate(-1800px, -1000px);
  -webkit-transform: translate(-1800px, -1000px);
  transform: translate(-1800px, -1000px);

  -moz-animation: plane-animation 20s linear infinite;
  -webkit-animation: plane-animation 20s linear infinite;
  animation: plane-animation 20s linear infinite;
}

.flappynomic-sec .kite-1 {
  position: absolute;
  bottom: 111px;
  right: 37px;
  -webkit-animation: mover 4s infinite alternate;
  animation: mover 4s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

.flappynomic-sec .kite-2 {
  position: absolute;
  left: 350px;
  top: -60px;
  -webkit-animation: mover 6s infinite alternate;
  animation: mover 6s infinite alternate;
}

.flappynomic-sec .plance-2 {
  position: absolute;
  top: 30%;
  left: -60px;
  -webkit-animation: animateCloud 20s linear infinite;
  -moz-animation: animateCloud 20s linear infinite;
  animation: animateCloud 20s linear infinite;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.flappynomic-sec .plance-3 {
  position: absolute;
  right: -19px;
  top: -78px;
  animation: bounce2 5s ease infinite;
}

/* for Firefox */
@-moz-keyframes plane-animation {
  from {
    -moz-transform: translate(1800px, 1000px);
  }

  to {
    -moz-transform: translate(-1800px, -1000px);
  }
}

/* for Chrome */
@-webkit-keyframes plane-animation {
  from {
    -webkit-transform: translate(1800px, 1000px);
  }

  to {
    -webkit-transform: translate(-1800px, -1000px);
  }
}

@keyframes plane-animation {
  from {
    -moz-transform: translate(1800px, 1000px);
    -webkit-transform: translate(1800px, 1000px);
    transform: translate(1800px, 1000px);
  }

  to {
    -moz-transform: translate(-1800px, -1000px);
    -webkit-transform: translate(-1800px, -1000px);
    transform: translate(-1800px, -1000px);
  }
}

/* Keyframes */
@keyframes wiggle {

  0%,
  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-15deg);
  }

  20% {
    transform: rotateZ(10deg);
  }

  25% {
    transform: rotateZ(-10deg);
  }

  30% {
    transform: rotateZ(6deg);
  }

  35% {
    transform: rotateZ(-4deg);
  }

  40%,
  100% {
    transform: rotateZ(0);
  }
}




@-webkit-keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}



@-webkit-keyframes animateCloud3 {
  0% {
    margin-right: -1000px;
  }

  100% {
    margin-right: 100%;
  }
}

@-moz-keyframes animateCloud3 {
  0% {
    margin-right: -1000px;
  }

  100% {
    margin-right: 100%;
  }
}

@keyframes animateCloud3 {
  0% {
    margin-right: -1000px;
  }

  100% {
    margin-right: 100%;
  }
}

/* Header Main Css Start*/

.flappytrump-page .header {
  position: relative;
  width: 100%;

  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.flappytrump-page .landing-marquee .marquee-container a i {
  color: #F2D4C4;
}

.flappytrump-page .landing-marquee {
  background: #4B220B;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.flappytrump-page .banner-section {
  position: relative;
  background-size: 100%;
  background-position: bottom right;
  background-repeat: no-repeat;
  padding: 171px 0 0;
}

.banner-area.banner-sec {
  position: relative;
  z-index: 8;
  height: 100vh;
  overflow: hidden;
  margin-top: -5px;
}

.flappytrump-page .banner-section .btn-area {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.lazy-label {
  margin-top: -96px;
}

.flappytrump-page .banner-section .banner-image {
  position: relative;
  text-align: right;
}

.flappytrump-page .banner-section .col-lg-5 {
  padding: 0;
}

.flappytrump-page .banner-section .banner-image .z-img {
  position: absolute;
  top: 89px;
  width: 186px;
  left: 245px;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.flappytrump-page .banner-section .banner-image .lazy-img {
  position: absolute;
  top: 68px;
  left: 88px;
  width: 200px;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}

.flappytrump-page .banner-section .banner-text {
  position: relative;
  margin-top: 105px;
}

.flappytrump-page .banner-section .banner-text h1 {
  font-size: 160px;
  color: #f3d33d;
  text-transform: uppercase;
  font-family: 'Funny and Cute';
  text-shadow: 4px 0 0 BLACK;
}

.flappytrump-page .banner-section .banner-image img {
  width: 385px;
}

.flappytrump-page .banner-section .banner-text h1 span {
  font-size: 215px;
}

.flappytrump-page .banner-section .banner-text p {
  font-size: 18px;
  font-weight: 400;
  color: #262741;
  line-height: 29px;
  margin: 20px 0 38px;
}

.flappytrump-page .banner-section .banner-text .btn-area {
  position: relative;
}

.flappytrump-page .banner-section .banner-text .btn-area ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 350px;
}

.flappytrump-page .banner-section .banner-text .btn-area ul li {
  margin-right: 21px;
  margin-bottom: 23px;
}

.flappytrump-page .banner-section .banner-text .btn-area ul li .whitepaper,
.flappytrump-page .banner-section .banner-text .btn-area ul li .tenshi:hover {
  background-color: #F2D4C4;
  color: #4B220B !important;
}

.flappytrump-page .banner-section .banner-text .btn-area ul li .tenshi,
.flappytrump-page .banner-section .banner-text .btn-area ul li .whitepaper:hover {
  background-color: #4B220B;
  color: #F2D4C4 !important;
}




.flappytrump-page .about-sec {
  position: relative;
  padding: 100px 0 0;

}

.flappytrump-page .about-sec .heading-area-left {
  zoom: 1;
  padding-bottom: 0;
  padding-right: 100px;
}

.flappytrump-page .about-sec .heading-area-left h2 {
  font-size: 100px;
  color: #f3d33d;
  font-family: 'Funny and Cute';
  text-shadow: 4px 0 0 BLACK;
  margin-bottom: 30px;
}

.flappytrump-page .about-sec .heading-area-left .text-box {
  background-image: url('../../static/images/test-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 53px 50px 90px;
  margin-bottom: 80px;
}

.flappytrump-page .about-sec .heading-area-left .text-box p {
  font-size: 40px;
  margin-bottom: 0;
  line-height: 58px;
  color: #fff;
  font-family: 'Impact Regular';
}

.flappytrump-page .about-sec .heading-area-right {
  position: relative;
  text-align: right;
  zoom: 1;
  padding-bottom: 0;
  margin-right: -153px;
}

.gameover-screen .tump-image {
  position: absolute;
  top: 75px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
}

.gameover-screen .score-gameover {
  background-image: none;
  color: #fff;
  top: 188px;
  z-index: 99;
}

.icon-bottom {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -29px;
}

.icon-bottom button {
  background-color: transparent;
  border: none;
  width: 47px;
  margin: 0 10px;
  position: relative;
  z-index: 9999;
}

.social-icons button.home-icon {
  background-color: transparent;
  border: none;
  width: 61px;
}

.social-icons {
  position: fixed;
  left: 0;
  bottom: 9px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 42px;
  z-index: 99;
}

.social-icons button.mute {
  background-color: transparent;
  border: none;
  width: 51px;
}

.social-icons .right-area {
  display: flex;
  align-items: center;
}

.social-icons .right-area .social {
  display: inline-block;
  margin-left: 25px;
  width: 51px;
}

.flappytrump-page .about-sec .text-block .text-box {
  margin-top: 100px;
}

.flappytrump-page .about-sec .text-block .text-box h4 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.flappytrump-page .about-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4B220B;
}


.form-sec {
  position: relative;
  padding: 100px 0;
}

.form-sec .heading-area-left {
  position: relative;
  margin-left: -147px;
  margin-top: 0;
  zoom: 0.8;
}

.form-sec .heading-area-left .heading-box h3 {
  padding-left: 100px;
  margin-top: 0;
  font-size: 100px;
  color: #f3d33d;
  font-family: 'Funny and Cute';
  text-shadow: 4px 0 0 BLACK;
  margin-bottom: 30px;
}

.form-sec .heading-area-left img {
  width: 47%;
}

.form-sec .heading-area-left .heading-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-sec .heading-area-left .heading-box h3 {
  padding-left: 80px;
  font-size: 100px;
  margin-top: 0;
  padding-right: 65px;
}

.form-sec .heading-area-left .title-box {
  position: relative;
  padding-bottom: 57px;
  padding-left: 100px;
}

.form-sec .heading-area-left .title-box h3 {
  margin-top: 0;
  font-size: 70px;
  color: #f3d33d;
  font-family: 'Funny and Cute';
  text-shadow: 4px 0 0 BLACK;
  margin-bottom: 30px;
  font-weight: 400;
}

.form-sec .heading-area-right {
  position: relative;
  text-align: right;
}

.form-sec .heading-area-right .heading-box {
  position: relative;
}

.form-sec .heading-area-right .heading-box .form-box {
  position: relative;
}

.form-sec .heading-area-right .heading-box .form-box iframe {
  width: 100%;
  height: 626px;
  border-radius: 20px;
  position: relative;
}

.join-sec {
  position: relative;
  padding: 100px 0 0;
}

.join-sec .heading-area-left {
  position: relative;
  z-index: 2;
}

.join-sec .heading-area-left .heading-box img {
  width: 361px;
}

.join-sec .heading-area-left .heading-box .join-img {
  position: absolute;
  right: -60px;
  animation: zoom-in-zoom-out-2 8s ease infinite;
  top: 115px;
}

.join-sec .row {
  align-items: end;
}

.join-sec .heading-area-right {
  position: relative;
}

.join-sec .heading-area-right .heading-box {
  position: relative;
  margin-bottom: 50px;
}

.join-sec .heading-area-right .heading-box ul {
  display: flex;
  align-items: center;
}

.join-sec .heading-area-right .heading-box ul li {
  margin-right: 77px;
}

.join-sec .heading-area-right .heading-box ul li a {
  display: block;
  transition: all 0.3s ease-in-out;
}

.join-sec .heading-area-right .heading-box ul li a:hover {
  color: #577d0d;
  filter: drop-shadow(3px 4px 3px black);
}

.credit-with-sec {
  position: relative;
  background-color: #fea058;
  padding: 15px 0;
  border-top: 9px solid #c3c2c4;
}

.credit-with-sec p {
  margin-bottom: 0;
  text-align: right;
  padding-right: 7%;
  font-style: italic;
  font-family: 'ITC Avant Garde Gothic LT';
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flappytrump-page .safe-sec {
  padding: 334px 0 140px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

}

.flappytrump-page .safe-sec .text-block .text-box {
  margin-top: 200px;
}

.flappytrump-page .safe-sec .text-block .text-box h4 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.flappytrump-page .safe-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4B220B;
}

.flappytrump-page .safe-sec .image-block {
  text-align: right;
}

.flappytrump-page .safe-sec .image-block .image-box {
  position: relative;
}

.flappytrump-page .safe-sec .image-block .image-box .scure-img {
  position: absolute;
  bottom: -38px;
  right: 161px;
  animation: zoom-in-zoom-out-2 2s ease infinite;
}

@keyframes zoom-in-zoom-out-2 {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out-3 {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}


@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}



@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.flappytrump-page .safe-sec .bootm-img {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}



.flappytrump-page .commenty-sec {
  position: relative;
  padding: 100px 0;

}

.flappytrump-page .commenty-sec .text-block .text-box {
  margin-top: 100px;
}

.flappytrump-page .commenty-sec .text-block .text-box h4 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.flappytrump-page .commenty-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4B220B;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flappytrump-page .commenty-sec .text-block .text-box .bottom-area {
  margin-top: 68px;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
  text-align: center;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}



.flappytrump-page .roadmap {
  position: relative;
  padding: 200px 0 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;

}

.flappytrump-page .roadmap:before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -5px;
  background: #4B220B;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: 100px;
}

/* roadmap start */
.flappytrump-page .roadmap .timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}

.flappytrump-page .roadmap .timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background: #4B220B;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.flappytrump-page .roadmap .timeline::before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: -5px;
  background: #4B220B;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.flappytrump-page .roadmap .timeline-container {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.flappytrump-page .roadmap .timeline-container.left {
  left: 0;
}

.flappytrump-page .roadmap .timeline-container.right {
  left: 50%;
}

.flappytrump-page .roadmap .timeline-container .count {
  position: absolute;
  right: -30px;
  top: 16px;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  background-color: #df956d;
  width: 59px;
  height: 59px;
  border-radius: 50%;
  text-align: center;
  line-height: 54px;
  z-index: 1;
}

.flappytrump-page .roadmap .timeline-container.right .count {
  right: auto;
  left: -30px;
}

.flappytrump-page .roadmap .timeline-container.right::after {
  left: -8px;
}

.flappytrump-page .roadmap .timeline-container.right::before {
  left: 8px;
}

.flappytrump-page .roadmap .timeline-container .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #006E51;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.flappytrump-page .roadmap .timeline-container.left .date {
  right: -75px;
}

.flappytrump-page .roadmap .timeline-container.right .date {
  left: -75px;
}

.flappytrump-page .roadmap .timeline-container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #F6D155;
  border: 2px solid #006E51;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #006E51;
  z-index: 1;
}

.flappytrump-page .roadmap .timeline-container.left .icon {
  right: 56px;
}

.flappytrump-page .roadmap .timeline-container.right .icon {
  left: 56px;
}

.flappytrump-page .roadmap .timeline-container .content {
  position: relative;
  text-align: right;
  padding-right: 20px;
  padding-top: 15px;
}

.flappytrump-page .roadmap .timeline-container.right .content {
  text-align: left;
  padding-left: 20px;
  padding-right: 0;
}

.flappytrump-page .roadmap .timeline-container .content h2 {
  font-size: 20px;
  color: #5b2e7a;
  margin-bottom: 14px;
  font-weight: 700;
}

.flappytrump-page .roadmap .timeline-container.active .content h2 {
  color: #df956d;
}

.flappytrump-page .roadmap .timeline-container .content p {
  margin: 0;
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 2px;
  color: #262741;
  font-weight: 500;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.flappytrump-page .swap-sec {
  padding: 490px 0 200px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;

}

.flappytrump-page .swap-sec .text-block {
  position: relative;
}

.flappytrump-page .swap-sec .text-block .text-box {
  position: relative;
  margin-top: 20px;
}

.flappytrump-page .swap-sec .text-block .text-box h5 {
  color: #4B220B;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.flappytrump-page .swap-sec .text-block .text-box h4 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.flappytrump-page .swap-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #262741;
}

.flappytrump-page .step-sec {
  position: relative;
  padding: 204px 0 223px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.flappytrump-page .step-sec .sec-title {
  margin-top: 0;
  margin-bottom: 20px;
}

.flappytrump-page .step-sec .step-block .image-box {
  position: absolute;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
  right: 11%;
  top: -188px;
}

.flappytrump-page .step-sec .step-block .step-box {
  position: relative;
  width: 60%;
  margin: 0 auto;
  padding-top: 60px;
}

.flappytrump-page .step-sec .step-block .step-box ul#myTab {
  display: flex;
  justify-content: center;
  border: none;
}

.flappytrump-page .step-sec .step-block .step-box ul#myTab li {
  margin-bottom: 0;
}

.flappytrump-page .step-sec .step-block .step-box ul#myTab a {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  color: #4B220B;
  padding: 0 20px 5px;
  margin: 0 15px;
  transition: all 0.3s ease-in-out;
}

.flappytrump-page .step-sec .step-block .step-box ul#myTab a.active,
.flappytrump-page .step-sec .step-block .step-box ul#myTab a:hover {
  border-bottom: 1px solid #4B220B;
}

.flappytrump-page .step-sec .step-block .step-box .tab-pane {
  margin-top: 50px;
}

.flappytrump-page .step-sec .step-block .step-box .tab-pane .inner-box {
  display: flex;
  flex-wrap: wrap;
}

.flappytrump-page .step-sec .step-block .step-box .tab-pane .inner-box .icon-box {
  width: 80px;
  height: 80px;
  background-color: #df956d;
  line-height: 80px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #4B220B;
  border-radius: 50%;
}

.flappytrump-page .step-sec .step-block .step-box .tab-pane .inner-box .text-box {
  width: 80%;
  margin-left: 40px;
}

.flappytrump-page .step-sec .step-block .step-box .tab-pane .inner-box .text-box h4 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.flappytrump-page .community-sec {
  position: relative;
  padding: 100px 0;
  background-color: #f2d4c4;
}

.flappytrump-page .community-sec .sec-title {
  margin: 0;
}

.flappytrump-page .community-sec .group-form {
  position: relative;
  width: 68%;
  margin: 0 auto 30px;
}

.flappytrump-page .community-sec .group-form input[type="text"] {
  width: 100%;
  background-color: #4B220B;
  color: #fff;
  padding: 20px 30px;
  border-radius: 42px;
  font-size: 16px;
}

.flappytrump-page .community-sec .group-form button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #4b220b;
  border: 1px solid #f2d4c4;
  padding: 13px;
  border-radius: 35px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.flappytrump-page .community-sec .group-form button:hover {
  background-color: #f2d4c4;
  border: 1px solid #f2d4c4;
  color: #4b220b;
}

.flappytrump-page .community-sec .sec-title p {
  font-size: 20px;
}

.flappytrump-page .community-sec .social-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 34px;
}

.flappytrump-page .community-sec .social-icons li {
  margin: 0 19px;
}

.flappytrump-page .community-sec .social-icons li a .icon img {
  width: 32px;
}

.flappytrump-page .graph-sec {
  position: relative;
  padding: 100px 0;
}

.flappytrump-page .graph-sec .sec-title {
  margin-top: 0;
}

.flappytrump-page .graph-sec .text-box {
  padding-left: 50px;
  padding-top: 24px;
}

.flappytrump-page .graph-sec .text-box h4 {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #4B220B;
}

.flappytrump-page .roadmap .sec-title {
  margin-top: 0;
}

.flappytrump-page .graph-sec .text-box .graph-list {
  list-style: circle;
  margin: 26px 22px;
  display: block;
}

.flappytrump-page .graph-sec .text-box .graph-list li {
  list-style: disc;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #4B220B;
  margin-bottom: 6px;
}



.flappytrump-page .coinmetrics-sec {
  position: relative;
  padding: 100px 0 40px;
}

.flappytrump-page .coinmetrics-sec .sec-title {
  margin-top: 0;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner .image-box {
  position: relative;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block:hover .coinmetrics-inner .image-box:before {
  transform: rotate(360deg);
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner .image-box h3 {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
  font-size: 40px;
  font-weight: 600;
  color: #4B220B;
}

.flappytrump-page .coinmetrics-sec {
  position: relative;
  padding: 100px 0;
}

.flappytrump-page .coinmetrics-sec .sec-title {
  margin-top: 0;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner {
  position: relative;
  text-align: center;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner .image-box {
  position: relative;
  margin-bottom: 50px;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner .image-box h3 {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
  font-size: 40px;
  font-weight: 600;
  color: #4B220B;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner .text-box {
  position: relative;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner .text-box h4 {
  font-size: 25px;
  margin-bottom: 16px;
  color: #4B220B;
  font-weight: 600;
}

.flappytrump-page .coinmetrics-sec .coinmetrics-block .coinmetrics-inner .text-box p {
  color: #4B220B;

}

.leaderboard-screen .user-score-area .tab-content {
  width: 100%;
  margin-top: 20px;
}

.leaderboard-screen .user-score-area .nav.nav-tabs {
  justify-content: center;
  border-bottom: 0;
  margin: 0 auto;
  font-family: 'Berlin Sans FB Demi';
}

.leaderboard-screen .user-score-area .nav-tabs .nav-item.show .nav-link,
.leaderboard-screen .user-score-area .nav-tabs .nav-link.active {
  background-color: #4B220B;
  border: none;
  border-radius: 7px;
  color: #fff;
  font-family: 'Berlin Sans FB Demi';
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.modal-enter-website .body-modal {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-image: url('../../static/images/entery-bg.jpg');
}

.modal-enter-website .body-modal .modal-inner h1 {
  font-family: 'CCThatsAllFolks';
  font-size: 60px;
  color: #fff;
  margin-bottom: 40px;
  -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000;
}

.modal-enter-website .body-modal .modal-inner {
  position: relative;
}


.modal-enter-website .body-modal .modal-inner .flapy-text {
  width: 700px;
}

.modal-enter-website .body-modal .modal-inner .flapy-text img {
  width: 100%;
}

.modal-enter-website .body-modal .modal-inner .entry-btn {
  position: relative;
  background-color: transparent;
  border: none;
  width: 277px;
  height: 65px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Berlin Sans FB';
  transition: all 0.3s ease-in-out;
  background-size: 100% 100%;
  text-align: center;
  font-size: 26px;
  animation: zoom-in-zoom-out-2 4s ease infinite;
  font-family: 'Berlin Sans FB';
  background-image: url('../../static/images/entery-bg-btn.png');
}

.modal-enter-website .body-modal .modal-inner .entry-btn:hover {
  filter: drop-shadow(5px 5px #b4511f);
  background-color: transparent;
}

.buy-sec {
  position: relative;
  margin-top: -14px;
  padding: 100px 0;
}

.buy-sec .img-buy {
  position: relative;
}

.buy-sec .img-buy img.bottom-shadow {
  margin-top: -62px;
  position: relative;
  left: -35px;
  z-index: 1;
}

.buy-sec .img-buy img {
  z-index: 2;
  position: relative;
}

.buy-sec .right-area {
  position: relative;
  text-align: right;
  display: flex;
  justify-content: end;
}

.buy-sec .right-area .form-box {
  position: relative;
  width: 81%;
  height: 555px;
}

/* 
.buy-sec .right-area .form-box {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 500px;
  height: 555px;
  background-image: url('../../static/images/buy-right-img.png');
} */
.buy-sec .row {
  align-items: center;
}

.buy-sec .right-area {
  position: relative;
  text-align: right;
  display: flex;
  justify-content: end;
}

.buy-sec .right-area .form-box {
  position: relative;
  width: 100%;
}

.buy-sec .right-area .form-box iframe {
  border-radius: 20px;
  height: 626px;
  position: relative;
  width: 100%;
}

.leaderboard-screen .user-score-area .user-score-box .user-area p.public-address {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 360px;
  white-space: nowrap;
  line-height: 35px;
  font-size: 22px;
}

.leaderboard-screen .user-score-area .nav-tabs .nav-link {
  margin-bottom: 0;
  border: none;
}

@media(max-width: 1700px) {
  .flappytrump-page .about-sec .heading-area-right {
    margin-right: -63px;
  }

  .form-sec .heading-area-left {
    margin-left: -57px;
  }

  .background-start .left-play-area {
    zoom: 1;
    bottom: 83px;
  }

  .cherctor-images {
    left: -35px;
    bottom: 119px;
  }

  .cherctor-images img {
    width: 170px;
    bottom: -46px;
  }

  .cherctor-images img.charctor-img {
    width: 245px;
  }

  .background-start .text-box {
    top: 170px;
  }
}

@media(max-width: 1600px) {
  .flappytrump-page .banner-section .banner-image {
    zoom: 0.8;
  }

  .flappytrump-page .banner-section .banner-text .btn-area ul {
    margin-top: 265px;
  }

  .flappytrump-page .about-sec .heading-area-right {
    zoom: 0.7;
    text-align: center;
  }

  .form-sec .heading-area-left {
    zoom: 0.7;
    margin-top: 72px;
  }

  .flappytrump-page {
    overflow: hidden;
  }

  .flappytrump-page .banner-section .banner-text h1 {
    font-size: 130px;
  }

  .flappytrump-page .banner-section .banner-text h1 span {
    font-size: 165px;
  }

  .form-sec .heading-area-left img {
    width: 60%;
  }

  .features-sec,
  .features-box-2,
  .features-box,
  .flappynomic-sec,
  .flappymap-sec,
  .join-sec {
    zoom: 0.8;
  }

  .features-box-2 {
    margin-top: -120px;
  }

  .features-area .features-box {
    margin-top: -128px;
  }

  .features-area .features-box.features-box-3 {
    margin-top: -150px;
  }

  .background-start .left-play-area {
    zoom: 0.95;
    bottom: 89px;
  }

  .background-start .text-box {
    top: 99px;
  }

  .cherctor-images {
    bottom: 108px;
  }

  .cherctor-images img {
    width: 158px;
    position: relative;
    top: 30px;
  }

  .features-box-2 .user-score-area {
    position: relative;
    zoom: 0.8;
    padding: 0 46px;
  }

  .background-start .text-box img {
    width: 300px;
  }

  .cherctor-images img.charctor-img {
    width: 216px;
    top: 0px;
  }

  section.banner-area .jmnKOe {
    height: 738px !important;
  }

  .buy-sec .img-buy img.bottom-shadow {
    margin-top: -72px;
    left: -35px;
  }

  .buy-sec .img-buy img {
    left: 52px;
    position: relative;
  }
}


@media(max-width: 1500px) {
  .features-box-2 .user-score-area {
    zoom: 0.7;
    padding: 0 16px;
  }

  .flappytrump-page .sec-title {
    zoom: 0.8;
  }

  .join-sec .sec-title {
    margin-bottom: 272px;
  }

  .flappynomic-sec .plance-1,
  .flappynomic-sec .kite-1,
  .flappynomic-sec .kite-2,
  .flappynomic-sec .plance-2,
  .flappynomic-sec .plance-3 {
    zoom: 0.8;
  }

  .flappynomic-sec .kite-2 {
    top: 60px;
  }

  .background-start .left-play-area {
    zoom: 0.9;
    bottom: 94px;
  }

  .background-start .text-box h3 .icon {
    width: 49px;
    margin-top: 3px;
  }

  .modal-enter-website .body-modal .modal-inner {
    zoom: 0.7;
  }

  .buy-sec .right-area .form-box iframe {
    height: 542px;
  }

  .buy-sec .img-buy img.bottom-shadow {
    margin-top: -46px;
    left: 40px;
  }
}

@media(max-width: 1400px) {
  .background-start .text-box {
    top: 149px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img img {
    width: 70%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .overlyabox {
    transform: translate(-50%, -22%);
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .arrows-img {
    top: -36px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box .img-box img {
    width: 61%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-1 {
    top: -151px;
    left: 91px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-2 {
    top: -139px;
    right: -209px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-3 {
    bottom: -80px;
    left: 120px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-4 {
    bottom: -14px;
    right: -264px;
  }

  .background-start .text-box {
    top: 98px;
  }

  .cherctor-images img.charctor-img {
    width: 173px;
    top: 0px;
  }

  .cherctor-images img {
    width: 125px;
    top: 30px;
  }

  .background-start .left-play-area {
    zoom: 0.75;
    bottom: 124px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg img {
    zoom: 0.8;
  }

  section.banner-area .jmnKOe {
    height: 604px !important;
  }

  section.banner-area .cpPPVm {
    height: 608px !important;
  }

  .background-start .gameover-screen {
    width: 267px;
    zoom: 0.8;
    margin: 0 auto;
  }

  .gameover-screen .tump-image,
  .gSVUvp {
    zoom: 0.7;
  }

  .gameover-screen .score-gameover {
    zoom: 0.75;
  }

  .icon-bottom {
    zoom: 0.6;
  }

  .cherctor-images {
    left: 50%;
    transform: translateX(-50%);
  }

  .buy-sec .img-buy {
    zoom: 0.7;
  }
}

@media(max-width: 1370px) {
  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .arrows-img {
    top: -22px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-3 {
    bottom: -96px;
    left: 120px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-2 {
    top: -123px;
    right: -209px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-1 {
    top: -132px;
    left: 91px;
  }
}

@media(max-width: 1199px) {
  .background-start .left-play-area {
    zoom: 0.8;
    bottom: 132px;
  }

  .banner-area.banner-sec .background-start {
    zoom: 0.8;
  }

  .banner-area.banner-sec {
    height: 550px;
  }

  .features-area .features-box {
    margin-top: -281px;
    zoom: 0.6;
  }

  .features-area .features-box.features-box-3 {
    margin-top: -306px;
  }

  .features-box-2 {
    zoom: 0.6;
  }

  .features-box-2 .user-score-area {
    zoom: 0.7;
    padding: 0 79px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg img {
    zoom: 0.7;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img img {
    width: 57%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .arrows-img img {
    width: 80%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .overlyabox {
    width: 130px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-1 {
    top: -115px;
    left: 217px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-2 {
    top: -99px;
    right: -209px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box .img-box img {
    width: 46%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-3 {
    bottom: -67px;
    left: 227px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-4 {
    bottom: -16px;
    right: -190px;
  }
}

@media(max-width: 991px) {
  .banner-area.banner-sec .background-start {
    zoom: 0.7;
  }

  .banner-area.banner-sec {
    height: 450px;
  }

  .features-sec .bttom-text p {
    font-size: 20px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block {
    zoom: 0.6;
  }

  .rocket {
    top: 104px;
  }

  .join-sec .sec-title {
    margin-bottom: 18px;
  }

  .join-sec .join-img {
    margin-bottom: 182px;
  }

  .cOwVyX {
    background-size: 107px 92px !important;
    width: 107px !important;
    height: 92px !important;
    top: 300px !important;
    left: 50px !important;
  }

  .flappynomic-sec .cloude-token .cloude-token-box {
    zoom: 0.5;
  }

  .background-start .left-play-area {
    zoom: 0.6;
    bottom: 181px;
  }

  .buy-sec .img-buy {
    zoom: 0.7;
    text-align: center;
  }

  .buy-sec .img-buy img {
    left: auto;
    position: relative;
    text-align: center;
    width: 38%;
    margin: 0 auto;
  }

  .buy-sec .img-buy img.bottom-shadow {
    left: auto;
    margin: -77px auto 50px;
    width: 64%;
  }

  .buy-sec .right-area {
    justify-content: center;
  }

  .buy-sec {
    position: relative;
    margin-top: -4px;
    padding: 100px 0;
  }

  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.7;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .overlyabox {
    transform: translate(-50%, -1%);
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-1 {
    top: -115px;
    left: 291px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-2 {
    top: -93px;
    right: -121px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-3 {
    bottom: -82px;
    left: 347px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-4 {
    bottom: -3px;
    right: -121px;
  }
}

@media(max-width: 750px) {
  .banner-area.banner-sec .background-start {
    zoom: 0.6;
  }

  .banner-area.banner-sec {
    height: 400px;
  }

  .social-icons ._wrapper_1sefb_1 {
    zoom: 0.3;
    width: 700px;
  }

  .features-box-2 {
    zoom: 0.5;
  }

  .features-area .features-box {
    margin-top: -281px;
    zoom: 0.5;
  }

  .flappytrump-page .sec-title {
    zoom: 0.6;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg img {
    zoom: 0.6;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-1 {
    left: 76px;
    top: 94px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-2 {
    left: 42%;
    top: 7px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-3 {
    right: 13px;
    top: -94px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block.roadmap-block-4 {
    right: 53px;
    top: 268px;
  }

  @-webkit-keyframes animPipe {
    0% {
      left: 450px;
      top: -160px;
    }

    50% {
      left: 225px;
      top: -80px;
    }

    100% {
      left: -100px;

    }
  }

  @-moz-keyframes animPipe {
    0% {
      left: 450px;
      top: -160px;
    }

    50% {
      left: 225px;
      top: -80px;
    }

    100% {
      left: -100px;

    }
  }

  @-o-keyframes animPipe {
    0% {
      left: 450px;
      top: -160px;
    }

    50% {
      left: 225px;
      top: -80px;
    }

    100% {
      left: -100px;

    }
  }

  @keyframes animPipe {
    0% {
      left: 450px;
      top: -160px;
    }

    50% {
      left: 225px;
      top: -80px;
    }

    100% {
      left: -100px;

    }
  }

  .flappynomic-sec .plance-1,
  .flappynomic-sec .kite-1,
  .flappynomic-sec .kite-2,
  .flappynomic-sec .plance-2,
  .flappynomic-sec .plance-3 {
    zoom: 0.4;
  }

  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.6;
  }
}

@media(max-width: 650px) {
  .banner-area.banner-sec .background-start {
    zoom: 0.5;
  }

  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.5;
  }

  .background-start .text-box {
    top: 104px;
  }

  .banner-area.banner-sec {
    height: 350px;
  }

  .features-box-2 {
    zoom: 0.4;
  }

  .features-area .features-box {
    zoom: 0.4;
  }

  .flappytrump-page .sec-title {
    zoom: 0.5;
  }

  .marque-area p {
    font-size: 14px;
  }

  .features-sec .bttom-text p {
    font-size: 15px;
    line-height: 21px;
  }
}

@media(max-width: 590px) {
  .banner-area.banner-sec .background-start {
    zoom: 0.4;
  }

  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.4;
  }

  .features-box-2 .user-score-area .user-score-box .user-area img {
    width: 21px;
  }

  .features-box-2 .user-score-area .user-score-box .user-area p.contery-name {
    width: 80px;
    font-size: 8px;
  }

  .banner-area.banner-sec {
    height: 300px;
  }

  .features-box-2 {
    zoom: 0.3;
  }

  .features-area .features-box {
    zoom: 0.2;
  }

  .flappytrump-page .sec-title {
    zoom: 0.4;
  }

  .features-box-2 .user-score-area {
    zoom: 0.7;
    padding: 0 24px;
  }

  .features-sec {
    padding: 43px 0 0;
  }

  .marque-area p {
    font-size: 11px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg .roadmap-block {
    zoom: 0.4;
  }

  .buy-sec .img-buy img {
    left: auto;
    position: relative;
    text-align: center;
    width: 67%;
    margin: 0 auto;
  }

  .buy-sec .img-buy img.bottom-shadow {
    left: auto;
    margin: -58px auto 50px;
    width: 84%;
  }
}

@media(max-width: 550px) {

  .banner-area.banner-sec .background-start,
  .background-start .left-play-area,
  .background-start .text-box {
    zoom: 1;
  }

  .background-start .text-box {
    top: 34px;
    width: 184px;
  }

  .background-start .text-box img {
    width: 80px;
  }

  .background-start .text-box h3 {
    font-size: 14px;
    -webkit-text-stroke: 1px #285100;
    text-stroke: 1px #285100;
  }

  .social-icons {
    zoom: 0.6;
  }

  .background-start .text-box h3 .icon {
    width: 16px;
    margin-right: 15px;
  }

  .banner-area.banner-sec {
    height: 250px;
  }

  .cherctor-images {
    bottom: 38px;
  }

  .cherctor-images img {
    width: 42px;
    top: 4px;
  }

  .cherctor-images img.charctor-img {
    width: 46px;
  }

  .background-start .left-play-area {
    bottom: 50px;
    left: 0;
    width: 145px;
    padding: 45px 30px 17px 37px;
  }

  .background-start .left-play-area .counter .icon {
    position: relative;
    width: 7px;
    display: inline-block;
    margin-right: 1px;
  }

  .background-start .left-play-area h3 .icon {
    width: 10px;
  }

  .background-start .left-play-area h3 {
    font-size: 9px;
    margin-bottom: 8px;
  }

  .background-start .left-play-area p {
    font-size: 4px;
    margin-bottom: 2px;
  }

  .banner-area.banner-sec .background-start::before {
    height: 51px;
    background-size: 100% 100%;
  }

  .rocket {
    top: 54px;
  }

  .flappymap-sec .roadmaap-area .roadmap-bg .rocket img {
    zoom: 0.4;
  }

  .features-sec .bttom-text p {
    font-size: 12px;
    line-height: 21px;
  }

  .background-start .left-play-area .counter {
    zoom: 1;
    width: 50px;
    height: 12px;
    font-size: 8px;
  }

  .features-area .features-box,
  .features-box-2 {
    zoom: 1;
  }

  .features-area .features-box h3 {
    font-size: 6px;
    margin-bottom: 11px;
  }

  .features-area .features-box p {
    font-size: 4px;
    line-height: 1;
  }

  .features-area .features-box,
  .features-area .features-box.features-box-3 {
    padding: 63px 10px 58px;
    width: 261px;
    height: 141px;
    margin-left: 0;
    margin-top: 0;
  }

  .features-area .features-box .img-box {
    margin-top: -11px;
  }

  .features-area .features-box .img-box img {
    width: 9px;
  }

  .features-area .features-box.features-box-3 .img-box {
    margin-top: -11px;
  }

  .features-area .features-box.features-box-3 .img-box img {
    width: 19px;
  }

  .features-area .features-box-2 h3 {
    font-size: 9px;
    margin-bottom: 6px;
  }

  .features-box-2 .midale-area img {
    width: 23px;
  }

  .features-box-2 .user-score-area .user-score-box .madile-img {
    width: 15px;
  }

  .features-box-2 .user-score-area .user-score-box .user-area img {
    width: 25px;
  }

  .features-box-2 {
    padding: 31px 10px 58px;
    width: 261px;
    height: 186px;
    margin-left: 0;
    margin-top: 0;
  }

  .features-box-2 .user-score-area .user-score-box .user-area img {
    width: 25px;
  }

  .features-box-2 .user-score-area .user-score-box .user-area p {
    font-size: 7px;
  }

  .features-box-2 .user-score-area .user-score-box .score-area {
    width: 65px;
    position: relative;
  }

  .features-box-2 .user-score-area .user-score-box .score-area p {
    font-size: 10px;
  }

  .features-area .features-box-2 p {
    line-height: 1;
    font-size: 8px;
  }

  .midale-area {
    margin-bottom: 2px;
  }

  .features-box-2 .user-score-area .score-top {
    padding-right: 0;
  }

  .features-box-2 .user-score-area {
    padding: 0 50px;
  }

  .features-box-2 .user-score-area {
    max-height: 90px;
  }

  .modal-enter-website .body-modal .modal-inner {
    zoom: 1;
  }

  .modal-enter-website .body-modal .modal-inner h1 {
    font-size: 35px;
  }

  .modal-enter-website .body-modal .modal-inner .flapy-text {
    width: 400px;
  }
}

@media(max-width: 500px) {
  .background-start .left-play-area {}

  .background-start .left-play-area h3 {
    font-size: 9px;
    margin-bottom: 4px;
  }

  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.35;
  }

  .background-start .left-play-area p {
    font-size: 5px;
    margin-bottom: 2px;
  }

  .background-start .left-play-area .counter {
    zoom: 1;
    width: 51px;
    height: 9px;
    font-size: 5px;
  }

  .leaderboard-screen {
    zoom: 0.3;
  }

  .background-start .left-play-area {}

  .background-start .left-play-area h3 {
    font-size: 9px;
    margin-bottom: 4px;
  }

  .background-start .left-play-area p {
    font-size: 5px;
    margin-bottom: 2px;
  }

  .background-start .left-play-area .counter {
    zoom: 1;
    width: 51px;
    height: 9px;
    font-size: 5px;
  }

  .buy-sec {
    position: relative;
    margin-top: -2px;
    padding: 25px 0;
  }
}


@media(max-width: 480px) {
  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img img {
    width: 74%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .arrows-img img {
    width: 100%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-center-img .overlyabox {
    width: 188px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box .img-box img {
    width: 67%;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-1 {
    top: -151px;
    left: 172px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-2 {
    top: -133px;
    right: -121px;
  }

  .flappynomic-sec .tokenomics-box-center .tokenomics-box.tokenomics-box-3 {
    bottom: -108px;
    left: 248px;
  }

  .flappynomic-sec .tokenomics-box-center {
    margin: 341px auto 100px;
  }

  .flappynomic-sec {
    padding: 55px 0 131px;
  }
}

@media(max-width: 420px) {
  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.3;
  }
}

@media(max-width: 370px) {
  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.25;
  }
}

@media(max-width: 335px) {
  .flappynomic-sec .tokenomics-box-center {
    zoom: 0.2;
  }
}

@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  @media all and (orientation: portrait) {

    .background-start .left-play-area h3,
    .background-start .left-play-area .counter,
    .features-area .features-box h3,
    .features-area .features-box-2 h3 {
      font-size: 14px;
    }

    .background-start .left-play-area p,
    .features-area .features-box p,
    .features-area .features-box-2 p,
    .features-box-2 .user-score-area .user-score-box .user-area p,
    .features-box-2 .user-score-area .user-score-box .score-area p {
      font-size: 8px;
    }
  }
}



@media (display-mode: fullscreen) {
  .cherctor-images img.charctor-img {
    width: 352px;
  }

  .cherctor-images img {
    width: 202px;
  }

  .background-start .text-box img {
    width: 550px;
  }

  .background-start .text-box h3 .icon {
    width: 82px;
  }

  .flappytrump-page .flappytrump-nav .navbar-nav li a {
    font-size: 22px !important;
  }
}