.flappytrump-page .flappytrump-nav {
  position: absolute;
  top: 11px;
  z-index: 9;
  width: 100%;
  font-family: 'Impacted Regular';
}

.flappytrump-page .flappytrump-nav .inner-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 4%;
  position: relative;
}

.wallet-btn {
  position: absolute;
  right: 0;
  zoom: 0.8;
}

.wallet-btn .wallet-inner {
  position: relative;
  background-color: transparent;
  border: none;
  width: 277px;
  height: 65px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Berlin Sans FB Demi' !important;
  transition: all 0.3s ease-in-out;
  background-size: 100% 100%;
  background-image: url('../../static/images/menu-btn-bg.png');
}
.wallet-btn .wallet-inner .icon-flag {
  width: 24px;
  display: inline-block;
  margin-left: 45px;
  margin-top: 18px;
  position: relative;
  top: -3px;
}
.wallet-btn .wallet-inner .icon-flag img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.wallet-btn .wallet-adapter-dropdown {
  position: relative;
  width: 100%;
}
.wallet-btn .wallet-inner .icon-flag {
  width: 24px;
  display: inline-block;
  margin-left: 45px;
  margin-top: 18px;
}
.wallet-btn .wallet-inner .score-area {
  display: flex;
  align-items: center;
  margin-top: 0;
  position: absolute;
  bottom: 9px;
  text-align: center;
  justify-content: center;
  border: 2px solid #680d00;
  width: 108px;
  border-radius: 30px;
  height: 23px;
  zoom: 0.9;
  left: 100px;
}
.wallet-btn .wallet-inner .score-area .icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f2a031;
  margin-right: 6px;
}
.wallet-btn .wallet-inner .score-area p {
  font-family: 'Berlin Sans FB Demi' !important;
  text-transform: uppercase;
  color: #fff;
  margin-left: 0;
  margin-bottom: 0;
  font-size: 13px;
}
.wallet-btn .wallet-inner:hover,
.wallet-btn .wallet-adapter-button:not([disabled]):hover {
  filter: drop-shadow(5px 5px #b4511f);
  background-color: transparent;
}
.wallet-btn .wallet-inner .wallet-adapter-dropdown button {
  background-color: transparent;
  border: none;
  font-family: 'Berlin Sans FB Demi' !important;
  text-transform: uppercase;
  width: 100%;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: -76px;
  width: 100%;
  height: 86px;
  padding-left: 100px;
  z-index: 99;
}

.wallet-btn .wallet-inner .wallet-adapter-dropdown button .wallet-adapter-button-start-icon {
  display: none;
} 
.flappytrump-page .landing-marquee .marquee-container a {
  margin-right: 15px;
}

.flappytrump-page .flappytrump-nav .inner-container .navbar-brand {
  width: 7%;
}

.navbar {
  padding: 0 !important;
}

.navbar .button-head button .login-loader {
  padding: 0px 40px;
}

.navbar .button-head button {
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  padding: 13px 30px;
  border-radius: 50px;
  font-family: 'Impacted Regular';
  background-color: #543c90;
}

.navbar .button-head span {
  border: none;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  box-shadow: none;
  padding: 13px 45px;
  border-radius: 50px;
  font-family: 'Impacted Regular';
  background-color: #543c90;
}

.navbar .button-heads button {
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  padding: 13px 36px;
  margin-left: 5px;
  margin-top: 24px;
  border-radius: 50px;
  font-family: 'Impacted Regular';
  background-color: #543c90;
}

.navbar .button-heads span {
  border: none;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  box-shadow: none;
  padding: 13px 45px;
  border-radius: 50px;
  font-family: 'Impacted Regular';
  background-color: #543c90;
}

/* .navbar .button-head span:hover,
.navbar .button-head button:hover {  
  background-color: #543c90;
} */
.img-trust-wallet {
  border-radius: 12px;
  margin-right: 26px;
}

.signup-modal .connect-wallat-signup .text-meta-mask-signup h5 span img {
  width: 5% !important;
}

.flappytrump-page .flappytrump-nav .navbar-collapse {
  justify-content: center;
  width: 85%;
  position: relative;
  top: -26px;
}

.flappytrump-page .flappytrump-nav .navbar-nav li {
  margin-right: 48px;
  transition: all 0.3s ease-in-out;
}

.flappytrump-page .flappytrump-nav .navbar-nav li a {
  font-size: 17px;
  color: #942a13;
  font-family: 'Impact Regular';
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.flappytrump-page .flappytrump-nav .navbar-nav li a:hover,
.flappytrump-page .flappytrump-nav .navbar-nav li a.active {
  color: #6ed806;
}

.flappytrump-page .flappytrump-nav .navbar-collapse .close-btn-area {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.4s ease-in-out;
}

.flappytrump-page .flappytrump-nav .navbar-collapse.show .close-btn-area {
  left: 0;
}

.flappytrump-page .flappytrump-nav .navbar-collapse .close-btn-area .close-button {
  background-color: transparent;
  padding: 0;
  top: 55px;
  right: 28px;
  transition: all 0.4s ease-in-out;
}

.flappytrump-page .flappytrump-nav .navbar-collapse .close-btn-area .close-button i {
  font-size: 50px;
  font-style: normal;
}


.whatsapp-swipe {
  position: fixed;
  top: 50%;
  right: 15px;
  z-index: 99;
  width: 100%;
  -webkit-transform: translateY(-25%);
  transform: translateY(-25%);
  width: 55px;
}

.whatsapp-swipe ul {
  z-index: 999;
  position: relative;
}

.whatsapp-swipe ul li {
  display: block;
  position: relative;
  z-index: 9999999;
  margin-bottom: 30px;
}

.whatsapp-swipe ul li a {
  width: 55px;
  height: 55px;
  display: block;
  margin-right: 0;
  border-radius: 50%;
  position: relative;
}

.whatsapp-swipe ul li a:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;

  border-radius: 50%;
  -webkit-animation: pulse-border 1.5s cubic-bezier(.77, 0, .175, 1) infinite;
  animation: pulse-border 1.5s cubic-bezier(.77, 0, .175, 1) infinite;
}

.whatsapp-swipe ul li a.whatsapp-icon:before {
  border: 3px solid #000;
}

.whatsapp-swipe ul li a.skype-icon:before {
  border: 3px solid #00baf0;
}

.whatsapp-swipe ul li a.telegram-icon:before {
  border: 3px solid #00baf0;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1
  }

  to {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0
  }
}

.whatsapp-icon {
  background: url('../../static/images/dex-logo.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 45px;
}

.skype-icon {
  background: url('../../static/images/raydium-logo.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 45px;
}

.telegram-icon {
  background: url('https://assets-softtik.s3.us-east-2.amazonaws.com/images/telegram.webp');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 45px;
}

@media(max-width: 1400px) {
  .flappytrump-page .flappytrump-nav .navbar-nav li a {
    font-size: 16px;
  }

  .wallet-btn .wallet-inner {
    zoom: 0.6;
  }
}

@media(max-width: 991px) {
  .flappytrump-page .flappytrump-nav .navbar-collapse {
    justify-content: center;
    width: 77%;
    flex-basis: auto;
    display: flex;
  }

  .navbar-nav {
    flex-direction: row !important;
  }

  .flappytrump-page .flappytrump-nav .navbar-nav li a {
    font-size: 14px;
  }
}

@media(max-width: 750px) {
  .flappytrump-page .flappytrump-nav .inner-container .navbar-brand {
    width: 8%;
    padding: 0;
    position: relative;
    top: -5px;
  }

  .navbar {
    zoom: 0.7;
  }

  .flappytrump-page .flappytrump-nav .navbar-nav li {
    margin-right: 20px;
  }
}

@media(max-width: 550px) {
  .flappytrump-page .flappytrump-nav .navbar-nav li {
    margin-right: 33px;
  }

  .flappytrump-page .flappytrump-nav .navbar-collapse {
    width: 70%;
  }

  .flappytrump-page .flappytrump-nav .navbar-nav li a {
    font-size: 11px;
  }

  .flappytrump-page .flappytrump-nav .navbar-nav li a {
    margin: 0 -7px;
  }

  .flappytrump-page .flappytrump-nav .inner-container {
    margin: 0;
  }
  .whatsapp-swipe ul li a{
    width: 34px;
    height: 34px;
  }
  .whatsapp-swipe ul li a:before{
    width: 45px;
    height: 45px;
  }
  .whatsapp-swipe{
    right: 0;
  }
}

@media(max-width: 480px) {
  .flappytrump-page .flappytrump-nav .navbar-collapse{
    justify-content: flex-start;
  }
  .flappytrump-page .flappytrump-nav .navbar-collapse{
    top: -15px;
  }
}