@font-face {
  font-family: 'Berlin Sans FB Demi';
  src: url('../fonts/BerlinSansFBDemi-Bold.eot');
  src: url('../fonts/BerlinSansFBDemi-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BerlinSansFBDemi-Bold.woff2') format('woff2'),
      url('../fonts/BerlinSansFBDemi-Bold.woff') format('woff'),
      url('../fonts/BerlinSansFBDemi-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Berlin Sans FB';
  src: url('../fonts/BerlinSansFB-Reg.eot');
  src: url('../fonts/BerlinSansFB-Reg.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BerlinSansFB-Reg.woff2') format('woff2'),
      url('../fonts/BerlinSansFB-Reg.woff') format('woff'),
      url('../fonts/BerlinSansFB-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Berlin Sans FB';
  src: url('../fonts/BerlinSansFB-Bold.eot');
  src: url('../fonts/BerlinSansFB-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BerlinSansFB-Bold.woff2') format('woff2'),
      url('../fonts/BerlinSansFB-Bold.woff') format('woff'),
      url('../fonts/BerlinSansFB-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CCThatsAllFolks';
  src: url('../fonts/CCThatsAllFolks-Bold.eot');
  src: url('../fonts/CCThatsAllFolks-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/CCThatsAllFolks-Bold.woff2') format('woff2'),
      url('../fonts/CCThatsAllFolks-Bold.woff') format('woff'),
      url('../fonts/CCThatsAllFolks-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Impact Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Impact Regular'), url('../fonts/impact.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Impact Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Impact Regular'), url('../fonts/unicode.impact.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Impacted Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Impacted Regular'), url('../fonts/Impacted.woff') format('woff');
  }

*{
  margin:0px;
	padding:0px;
	border:none;
	outline:none;
	font-size: 100%;
}
body {
 
  font-family: 'Impact Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:16px;
  color: rgba(75,34,11,0.8);
	line-height: 30px;
	font-weight:400;
	background:#fff;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}
code {
  font-family: 'Impact Regular';
}


.auto-container{
	position:static;
	max-width:1440px;
	padding:0px 15px;
	margin:0 auto;
}


h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.2em;
	font-family: 'Impact Regular';
}

textarea{
	overflow:hidden;	
	resize: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

p, .text{
	font-size:16px;
	line-height: 30px;
	font-weight: 400;
	color: #4B220B;
	margin: 0;
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

img{
  display:inline-block;
  max-width:100%;
  height:auto;  
}
::-webkit-input-placeholder{color: inherit;}
::-moz-input-placeholder{color: inherit;}
::-ms-input-placeholder{color: inherit;}

.app-text {
  text-align: center;
  color: #61dafb;
}
  
.flappytrump-page {
  background-color: #46c7f2;
  overflow: hidden;
}
/* width */
::-webkit-scrollbar {
	width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #000 ;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #f5f5f5;
  border: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #f5f5f5;
  border: 6px;
  }


  @media(max-width: 1500px){
    .auto-container{
      max-width: 1186px;
    }
  }